<template>
  <div class="aboutbox">
    <div class="img_header"><img :src="encnimg[num].imgurl" alt="" /></div>
    <div class="fs30">{{aboutdata[num].title}}</div>
    <div class="fs17">
      {{aboutdata[num].content}}
    </div>

    <div class="img_footer"><img src="../../img/about.png" alt="" /></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["num"]),
  },
  data() {
    return {
      encnimg:[
        {
          imgurl:require('../../img/headerimg.png')
        },
        {
          imgurl:require('../../img/headerenimg.png')
        }
      ],
        aboutdata:{
            0:{
                title:'关于苏梅岛',
                content:'苏梅岛位于泰国南部，距离曼谷首府约40分钟航程，是泰国第三大岛，面积仅为普吉岛的40%，是一带一路连接马来西亚和新加坡的必经之路。'
            },
            1:{
                title:'About Koh Samui',
                content:`Koh Samui is located in the south of Thailand, about 40 minutes' drive from the capital of Bangkok. It is the third largest island in Thailand, with an area of only 40% of Phuket. It is part of the Belt and Road Initiative connecting Malaysia and Singapore.`
            }
        }
    };
  },
};
</script>

<style lang="less" scoped>
.img_footer {
  width: 100%;
  margin-top: 0.3rem;
  img {
    width: 100%;
  }
}
.aboutbox {
  width: 8rem;
  height: 100%;
  margin: 0 auto;
  // background-color: antiquewhite;
}
.img_header {
  height: 0.8rem;
  width: 4.26rem;
  margin: 0 auto;
  img{
    width: 100%;
  }
}
.fs30 {
  margin-top: 0.8rem;
  font-size: 0.3rem;
  text-align: center;
}
.fs17 {
  margin-top: 0.3rem;
  font-size: 0.17rem;
  font-weight: 300;
  line-height: 0.25rem;
}
</style>